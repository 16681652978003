import './assets/main.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import vue3GoogleLogin from 'vue3-google-login';

import App from './App.vue';
import router from './router';

// Dynamically import icons
const loadIcons = async () => {
  const { fas } = await import('@fortawesome/free-solid-svg-icons');
  const { far } = await import('@fortawesome/free-regular-svg-icons');
  const { fab } = await import('@fortawesome/free-brands-svg-icons');
  library.add(fas, far, fab);
};

loadIcons();

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.use(createPinia());
app.use(router);
app.use(vue3GoogleLogin, {
    clientId: '557180247873-tfnj420m4csjkisd2hsvotur7gh04jp0.apps.googleusercontent.com'
});

app.mount('#app');