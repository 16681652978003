import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            };
        }
        return savedPosition || { top: 0 };
    },
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'landingpage',
            component: () => import('../views/LandingPageView.vue')
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('../views/DashboardView.vue')
        },
        {
            path: '/achievements',
            name: 'achievements',
            component: () => import('../views/AchievementsView.vue')
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('../views/UserProfileView.vue')
        },
        {
            path: '/profile/:username',
            name: 'user-profile',
            component: () => import('../views/UserProfileView.vue')
        },
        {
            path: '/profile/achievements',
            name: 'user-profile-achievements',
            component: () => import('../views/AchievementsView.vue')
        },
        {
            path: '/profile/:username/achievements',
            name: 'user-profile23432',
            component: () => import('../views/AchievementsView.vue')
        },
        {
            path: '/profile/settings',
            name: 'settings',
            component: () => import('../views/UserProfileSettingsView.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/LoginView.vue')
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import('../views/SignUpView.vue')
        },
        {
            path: '/signup/username',
            name: 'signup-username',
            component: () => import('../views/UsernameInputView.vue')
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('../views/ForgotPasswordView.vue')
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: () => import('../views/PrivacyView.vue')
        },
        {
            path: '/tos',
            name: 'tos',
            component: () => import('../views/ToSView.vue')
        },
        {
            path: '/verify-email',
            name: 'verify-email',
            component: () => import('../views/VerifyEmailView.vue')
        },
        {
            path: '/confirm',
            name: 'confirm',
            component: () => import('../views/ConfirmView.vue')
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('../views/ResetPasswordView.vue')
        }
    ]
});

const validateToken = async (token: string): Promise<{ valid: boolean }> => {
    const response = await fetch(`${import.meta.env.VITE_API_URL}/verify-token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
    });

    if (!response.ok) {
        return { valid: false };
    }

    return { valid: true }; // purchaseStatus mit zurückgeben
};

router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem('token') ?? '';
    const publicPages = [
        'login',
        'signup',
        'signup-username',
        'forgot-password',
        'privacy',
        'tos',
        'reset-password',
        'landingpage',
        'confirm',
        'verify-email'
    ];
    const authRequired = !publicPages.includes(to.name as string);

    if (authRequired) {
        const { valid } = await validateToken(token);

        if (!valid) {
            next({ name: 'login' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
