<template>
    <Transition name="fade">
        <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center z-50">
            <div class="absolute inset-0 bg-black/50"></div>
            <div
                class="max-w-sm md:max-w-lg lg:max-w-xl w-full bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-2xl z-10">
                <div class="p-6 md:p-8">
                    <div class="flex items-center justify-between mb-6">
                        <h2 class="text-2xl font-bold text-gray-900 dark:text-white">Processing your request...</h2>
                    </div>
                    <div class="flex items-center space-x-6">
                        <div class="relative">
                            <div class="absolute inset-0 bg-black rounded-full animate-ping opacity-75"></div>
                            <div class="relative z-10 flex items-center justify-center w-16 h-16 bg-black rounded-full">
                                <font-awesome-icon icon="fa-solid fa-mug-hot" class="w-6 h-6 text-white" />
                            </div>
                        </div>
                        <div>
                            <p class="text-lg text-gray-700 dark:text-gray-300 mb-2">
                                This usually takes about a minute.
                            </p>
                            <p class="text-sm text-gray-500 dark:text-gray-400">
                                Feel free to close this window. We'll update your data as soon as we're done!
                            </p>
                        </div>
                    </div>

                    <div class="mt-6 flex items-center justify-between">
                        <label class="flex items-center cursor-pointer">
                            <input type="checkbox" v-model="doNotShowAgain" class="sr-only peer"
                                @change="handleCheckboxChange">
                            <div
                                class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black">
                            </div>
                            <span class="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">Don't show this
                                again</span>
                        </label>

                        <button @click="handleClose"
                            class="px-6 py-2 bg-black text-white rounded-lg hover:bg-black transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 dark:focus:ring-offset-gray-800">
                            Got it
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

interface Props {
    isVisible: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
    'update:isVisible': [value: boolean];
}>();

const doNotShowAgain = ref(false);

onMounted(() => {
    const dismissed = localStorage.getItem('waitingMessageDismissed');
    if (dismissed === 'true') {
        emit('update:isVisible', false);
    }
});

function handleCheckboxChange() {
    if (doNotShowAgain.value) {
        localStorage.setItem('waitingMessageDismissed', 'true');
    } else {
        localStorage.removeItem('waitingMessageDismissed');
    }
}

function handleClose() {
    emit('update:isVisible', false);
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease, transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scale(0.9);
}
</style>