export function getUserIdFromToken() {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    
    // Decode the token to extract the user ID
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.id;
}

export function getUsernameFromToken() {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    
    // Decode the token to extract the username
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.username;
}

export function getToken() {
    return localStorage.getItem('token');
}