<template>
  <header v-if="!isLoginPage" class="border-b border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800">
    <div class="container mx-auto px-4 py-2 flex items-center justify-between">
      <div class="flex items-center space-x-2" @click="router.push('/dashboard')">
        <img src="./icons/icon.png" alt="Logo" class="h-12 cursor-pointer" />
        <h1 class="text-2xl font-bold cursor-pointer text-gray-900 dark:text-white">LexelUp</h1>
      </div>
      <div class="flex items-center space-x-4">
        <div v-if="route.path === '/dashboard'" class="flex items-center gap-2">
          <button @click="handleClick" class="flex items-center gap-2 px-4 py-2 rounded bg-black text-white">
            <font-awesome-icon icon="fa-solid fa-rotate-right" class="text-xl" />
            <span class="hidden sm:inline">Refreshes Left: {{ refreshesLeft }}</span>
            <span class="sm:hidden">{{ refreshesLeft }}</span>
          </button>
        </div>
        <div class="relative flex items-center cursor-pointer" @click="router.push('/profile')">
          <img :src="userImage" alt="User Image" class="w-10 h-10 rounded-full cursor-pointer" />
        </div>
      </div>
    </div>
  </header>
  <WaitingMessage v-model:isVisible="showWaitingMessage" />
  <NoAccountsMessage v-model:isVisible="showNoAccountsMessage" />
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import router from '@/router';
import { computed, onMounted, ref, watch } from 'vue';
import { getToken, getUserIdFromToken } from '@/utils/tokenUtils';
import { jwtDecode } from 'jwt-decode';
import WaitingMessage from './WaitingMessage.vue';
import NoAccountsMessage from './NoAccountsMessage.vue';

const route = useRoute();
const isLoginPage = computed(() => ['/login', '/signup', '/forgot-password', '/', '/tos', '/privacy', '/signup/username', '/verify-email', '/confirm', '/reset-password'].includes(route.path));
const userImage = ref('');
const showWaitingMessage = ref(false);
const showNoAccountsMessage = ref(false);
const refreshesLeft = ref(3);

function refreshData() {
  showWaitingMessage.value = true;
  fetch(`${import.meta.env.VITE_API_URL}/refresh-data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${getToken()}`
    },
    body: JSON.stringify({ userId: getUserIdFromToken() })
  })
    .then(async response => {
      if (!response.ok) {
        const error = await response.json();
        if (response.status === 404) {
          showWaitingMessage.value = false;
          showNoAccountsMessage.value = true;
        }
        return await Promise.reject(error);
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    .finally(() => {
      showWaitingMessage.value = false;
      loadRefreshesLeft();
    });
}

function handleClick() {
  if (refreshesLeft.value > 0) {
    refreshData();
    return;
  }
  router.push({ path: '/', hash: '#pricing' });
}

async function loadRefreshesLeft() {
  await fetch(`${import.meta.env.VITE_API_URL}/refreshs-left`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${getToken()}`
    },
    body: JSON.stringify({ userId: getUserIdFromToken() })
  })
    .then(async response => {
      if (!response.ok) {
        const error = await response.json();
        return await Promise.reject(error);
      }
      const data = await response.json();
      refreshesLeft.value = data.refreshsLeft;
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

onMounted(async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return;
  }
  const decodedToken = jwtDecode(token);
  const username = (decodedToken as { username: string; }).username;
  if (username) {
    userImage.value = `https://api.dicebear.com/9.x/bottts-neutral/svg?seed=${username}`;
  }
  await loadRefreshesLeft();
});

watch(() => route.path, (newPath) => {
  if (newPath === '/dashboard') {
    loadRefreshesLeft();
  }
});
</script>

<style scoped></style>
