<template>
    <Transition name="fade">
        <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center z-50">
            <div class="absolute inset-0 bg-black/50" @click="$emit('update:isVisible', false)"></div>
            <div class="max-w-sm md:max-w-lg lg:max-w-xl w-full bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-2xl z-10">
                <div class="p-6 md:p-8">
                    <!-- Header -->
                    <div class="flex items-center justify-between mb-6">
                        <h2 class="text-2xl font-bold text-gray-900 dark:text-white">No Connected Accounts</h2>
                        <button 
                            @click="$emit('update:isVisible', false)" 
                            class="text-black hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200"
                        >
                            <font-awesome-icon icon="fa-solid fa-xmark" class="w-6 h-6" />
                        </button>
                    </div>

                    <!-- Content -->
                    <div class="flex items-center space-x-6">
                        <div class="relative">
                            <div class="relative z-10 flex items-center justify-center w-16 h-16 bg-black rounded-full">
                                <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="w-8 h-8 text-white" />
                            </div>
                        </div>
                        <div>
                            <p class="text-lg text-gray-700 dark:text-gray-300 mb-2">
                                You need to connect at least one account.
                            </p>
                            <p class="text-sm text-gray-500 dark:text-gray-400">
                                Please add your social media accounts to start refreshing your data.
                            </p>
                        </div>
                    </div>

                    <!-- Actions -->
                    <div class="mt-6 flex items-center justify-end space-x-4">
                        <button 
                            @click="handleConnectAccounts"
                            class="px-6 py-2 bg-black text-white rounded-lg hover:bg-black/80 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 dark:focus:ring-offset-gray-800"
                        >
                            Connect Accounts
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';

interface Props {
    isVisible: boolean
}

const props = defineProps<Props>();
const emit = defineEmits<{
    'update:isVisible': [value: boolean]
}>();

const router = useRouter();

const handleConnectAccounts = () => {
    emit('update:isVisible', false);
    router.push('/profile/settings');
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease, transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scale(0.9);
}
</style>